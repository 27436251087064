.inputContainer {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 6px;
}

.inputContainer :global(input) {
  font-size: 21px;
}

.inputContainer :global(label) {
  font-size: 21px;
  line-height: 42px;
}

.inputTextbox {
  width: 50%;
}

/*.inputContainer :global(.searchButton) {*/
/*    height: 42px;*/
/*    width: 60px;*/
/*}*/

.cardContainer {
  display: flex;
  column-count: 3;
  flex-wrap: wrap;
}

.cardContainerItem {
  width: 33%;
  min-width: 33%;
}

.cardContainer :global(.ant-card) {
  margin: 10px;
}

.cardContainer :global(.ant-card-body) {
  height: 300px;
  min-height: 300px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.cardContainer :global(.ant-card-head-title) {
  font-weight: bold;
  text-align: center;
}

.cardContainer :global(.no-items) {
  text-align: center;
  font-style: italic;
  margin-top: 14px;
}

.cardContainer :global(.result-container) :global(.ant-menu) {
  max-height: 400px;
  height: 400px;
  overflow-y: scroll;
  width: 100%;
}

.boldLabel {
  font-weight: bold;
}


.searchResultListItem {
  height: unset !important;
  line-height: 1.5em !important;
  display: flex !important;
  margin-bottom: 0.5em !important;
  padding: 0.5em;
  :global(.ant-menu-title-content), :global(.ant-menu-title-content)>div {
    flex:1;
    overflow: hidden;
  }
  :global(.ant-menu-title-content)>div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  p {
    margin: 0;
  }

  .searchResultListItemHeader {
    .searchResultListItemTitleAndType{
      flex: 1;
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      .searchResultListItemTitle {
        margin-right: 1em;
        font-style: bold;
      }
      .searchResultListItemType {
        font-style: italic;
        margin-right: 1em;
        font-size: 0.9em;
        color:var(--blue-6);
      }
    }
    .searchResultListItemPath{
      font-size: 0.9em;
      opacity: 80%;
      flex: 1;
      color:var(--blue-6);

    }
  }

  .highlight {
    font-size: 0.9em;
    opacity: 50%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tooltipUrl {
  opacity: 70%;
}