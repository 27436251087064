@import './colour-palette.css';

@footer-height: 28px;
@desktop-header-height: 8px;
@desktop-content-margin: @desktop-header-height + @footer-height;

@mobile-header-height: 64px;
@mobile-content-margin: @mobile-header-height + @footer-height;

@small-border-radius: 2px;
@large-border-radius: 8px;

html,
body,
div#root {
  height: 100%;
  margin: 0;
}

div.app-background {
  height: 100%;
  display: flex;
  flex-direction: column;
}

section.ant-layout {
  flex-grow: 1;
}

aside,
aside.web-sider {
  height: 100%;

  li .ant-menu-title-content .sidebar-nav-item-title-icon-container a {
    display: flex;
    align-items: center;
  }
}

main {
  height: calc(100% - 16px);
}

.app-header-container {
  cursor: pointer;
}

.secondary-app-header-container h1 {
  text-align: center;
}

.app-footer {
  padding: 5px 15px 5px 50px !important;
  text-align: right;
  font-size: 12px !important;
}

.mobile .app-footer {
  padding: 5px 0 !important;
  text-align: center;
}

.ant-layout-content {
  overflow-x: auto;
  overflow-y: auto;
  min-height: 280px;
  text-align: center;
  margin-top: @desktop-header-height;
  border-radius: 4px 0 0 4px;
  border-right-width: 0;
  transition: padding 0.25s ease;

  /* this breaks the sidebar tooltips hover */
  //z-index: 10;
}

a {
  color: var(--blue-7);
}

.mobile .ant-layout-content {
  height: calc(100vh - @mobile-content-margin);
}

body.mobile .ant-layout-content {
  margin: 0;
  border: 0;
  border-radius: 0;
}

main.expanded-service-container.ant-layout-content {
  padding: 0;
}

/** Disable click events on disabled buttons */
.generic-popup-menu > .ant-dropdown-menu-item-disabled > * {
  pointer-events: none;
}

.ant-layout-sider-children .ant-menu-submenu-popup > ul > li.ant-menu-item {
  display: flex !important;
  align-items: center;
}

.ant-layout-sider-children .ant-menu-submenu-popup > ul > li.ant-menu-item > div:nth-child(2) {
  flex-grow: 1;
}

.ant-layout-sider-children .ant-menu-submenu-popup > ul > li.ant-menu-item > .ant-menu-title-content {
  display: flex;
  width: 100%;
}

.ant-layout-sider-children .ant-menu-submenu-popup > ul > li.ant-menu-item > .ant-menu-title-content > div {
  width: 100%;
}

.ant-layout-sider-children .ant-menu-submenu-popup > ul > li.ant-menu-item > .ant-menu-title-content > div > div {
  gap: 8px;
}

.ant-layout-sider-children .ant-menu-submenu-popup > ul > li.ant-menu-item > .ant-menu-title-content > a {
  flex-grow: 1;
}

.ant-layout-sider-children .ant-menu-inline .ant-menu-sub.ant-menu-inline,
.ant-layout-sider-children .ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: transparent;
}

.ant-layout-sider-children .ant-menu-submenu-popup > ul.ant-menu {
  border-radius: @large-border-radius;
}

.mobile-header-title {
  line-height: normal;
  font-size: 16px;
}

.header-menu-btn {
  font-size: 150%;
}

.ant-modal-header {
  border-top-left-radius: @large-border-radius;
  border-top-right-radius: @large-border-radius;
}

.ant-modal-content {
  border-radius: @large-border-radius;
  padding: 0 !important;
}

/** We need `!important` otherwise antd will override our properties */
.ant-modal-body {
  padding: 24px !important;
}

.ant-modal-no-padding > div > div > div.ant-modal-body {
  padding: 0 !important;
}

.ant-modal-footer {
  padding: 10px 16px !important;
}

.sidebar-nav-item-title-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.sidebar-nav-item-title-icon-container *:nth-child(1) {
  flex-grow: 1;
}

.sidebar-nav-item-title-icon-container > a {
  overflow: hidden;
  text-overflow: ellipsis;
}

.global-footer-text button.ant-btn,
.global-footer-text button.ant-btn:active,
.global-footer-text button.ant-btn:hover {
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  height: unset;
}

.global-sidebar-expanded {
  margin-left: 20px;
}

/** override the graphic labels. may not work/be silly */

text {
  font-family: 'Proxima Nova', sans-serif !important;
}

/** Text highlight color. Override ant theme. */
.light mark,
.dark mark {
  background-color: yellow !important;
}

/** PWA **/

.pwa-header {
  display: none;
  position: fixed;
  left: env(titlebar-area-x, 0);
  top: env(titlebar-area-y, 0);
  width: env(titlebar-area-width, 100%);
  height: env(titlebar-area-height, 33px);
  justify-content: left;
  align-items: center;
  color: var(--gray-1);
  padding-left: 0;
  font-size: 10pt;
  line-height: 42px;
  margin-left: 0;
  -webkit-app-region: drag;
  app-region: drag;
  text-align: center;
}

.ant-modal-mask {
  top: 0;
}

@media (display-mode: window-controls-overlay) {
  .app-header-container {
    display: none;
  }

  body,
  html {
    min-width: 800px;
  }

  .ant-layout-content {
    margin-top: 48px !important;
    height: calc(100vh - 58px) !important;
  }

  .light .ant-layout-sider,
  .dark .ant-layout-sider {
    margin-top: 48px !important;
    height: calc(100vh - 48px) !important;
  }

  .pwa-header {
    display: block;
  }

  .ant-modal-mask {
    top: 40px !important;
  }

  footer {
    display: none;
  }
}

.light .ant-btn-round,
.dark .ant-btn-round {
  border-radius: @small-border-radius !important;
}

button.ant-btn.ant-btn-primary {
  background: var(--blue-6);
}

.non-visible-sidebar main {
  margin-right: 8px;
}

.ant-alert,
.ant-select-dropdown,
.ant-dropdown-menu,
.ant-select-selector,
.ant-input-affix-wrapper,
.ant-picker {
  border-radius: @large-border-radius !important;
}

.ant-menu-item .ant-btn-dangerous {
  border-color: transparent !important;
}

.ant-radio-button-wrapper:first-child {
  border-start-start-radius: 2px;
  border-end-start-radius: 2px;
}

.ant-radio-button-wrapper:last-child {
  border-start-end-radius: 2px;
  border-end-end-radius: 2px;
}

.ant-modal-header {
  margin-bottom: 0 !important;
  padding: 16px 24px !important;
}

.ant-modal-title {
  font-weight: 500 !important;
}

.ant-modal-footer {
  margin-top: 0 !important;
}

.ant-layout-sider {
  background-color: transparent !important;
}

/* --- footer --- */

.global-footer-text button.ant-btn {
  color: var(--gray);
}

.global-footer-text button.ant-btn:active > span,
.global-footer-text button.ant-btn:hover > span {
  color: var(--gray);
  text-decoration: underline !important; /* override opinionated style in antd style sheet */
}

.app-footer,
.app-footer a {
  color: var(--gray);
}

.app-footer a:hover,
.app-footer a:active {
  text-decoration: underline;
}

/* --- end of footer --- */

aside {
  margin-right: 30px;
  margin-left: 10px;
}

p {
  margin-top: 0;
}

.ant-form-item {
  line-height: 2;
}

.ant-btn-icon > svg {
  width: 24px;
}

.ant-picker-input input {
  width: 100%;
  border: 1px solid var(--gray-5);
  font-size: 14px;
}

.ant-select-selection-item {
  align-self: normal !important;
}

.s3-uploader {
  .ant-row {
    display: flex;
    flex-flow: row wrap;
  }

  .ant-form-item {
    margin-bottom: 24px;
  }

  .ant-col-8 {
    display: block;
    text-align: end;
    flex: 0 0 33.33%;
    max-width: 33.33%;

    label {
      margin-right: 8px;
    }
  }

  .ant-col-16 {
    display: block;
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
}

.common-no-margins-ps p,
.light .common-no-margins-ps p,
.dark .common-no-margins-ps p {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-body-error {
  padding: 32px;
}

/* --- a11y colors fixes --- */
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--blue-6);
  border-color: var(--blue-6);
}

.ant-menu {
  &:not(.ant-menu-inline-collapsed) {
    .ant-menu-submenu-title {
      padding-right: 3.5em;

      & > i.ant-menu-submenu-arrow {
        padding: 18px 19px 14px 13px;
        transition: padding 0.3s;
        inset-inline-end: 12px;
      }
    }

    & .ant-menu-submenu-open .ant-menu-submenu-title {
      & > i.ant-menu-submenu-arrow {
        top: 6px;
        padding: 14px 19px 18px 13px;
        transition: padding 0.3s;
      }
    }
  }

  .ant-menu-submenu.sidenavButtonPressed i.ant-menu-submenu-arrow,
  .ant-menu-submenu.ant-menu-submenu-selected i.ant-menu-submenu-arrow {
    opacity: 1;
  }

  //&.ant-menu-inline-collapsed {
  //  overflow-x: hidden;
  //}
}

.ant-modal .ant-collapse.infoCollapse {
  font-size: 8pt;
  border-color: transparent;
  border-radius: @small-border-radius;
  box-shadow: none;
  margin: 1em 0;
  width: 100%;

  &.spaced {
    margin-top: 2em;
  }

  > .ant-collapse-item > .ant-collapse-header:focus {
    color: var(--gray-13);
  }

  & > .ant-collapse-item {
    border-color: transparent;
  }

  & > .ant-collapse-item > .ant-collapse-header {
    padding: 5px;
    border-color: transparent;
    box-shadow: none;

    & .anticon svg {
      overflow: visible;
    }

    & .ant-collapse-expand-icon {
      height: 16px;
    }

    & .ant-collapse-arrow {
      font-size: 8pt;
    }
  }

  & > .ant-collapse-item > .ant-collapse-content {
    border-color: transparent;
    box-shadow: none;
  }

  .ant-collapse-content-box {
    border-color: transparent;
    padding: 8px 16px;
  }
}

.ant-layout-sider .ant-menu .ant-menu-title-content {
  display: block;
}

/* Generic utility classes */

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.content-center {
  align-content: center;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.relative {
  position: relative;
}

.text-red {
  color: red;
}

.bottom-margin {
  margin-bottom: 16px;
}

.status-widget-container > .ant-alert {
  border-radius: 5px;
  padding: 1px 4px;
  margin-left: 10px;
  font-size: 0.8em;
  text-align: center;
}

.status-widget-container > .ant-alert span {
  animation-duration: 2500ms;
}

.status-widget-container > .ant-alert .ant-alert-content {
  margin-top: 1px;
}

.status-widget-container > .ant-alert .ant-space-gap-col-small {
  column-gap: 6px;
}

.ant-col > .ant-collapse {
  box-shadow: 0 0 12px 0 var(--gray-28);
}

.sidebar-group-item {
  margin-left: -16px;
}

.sidebar-group-item > span.anticon {
  padding-right: 6px !important;
}

#main_panel_container.settings-closed {
  background-color: black;
}

.ant-popover-inner {
  //margin-bottom: 2em;
  .ant-popover-inner-content {
    overflow-y: auto;
    max-height: 88vh;
  }
}

.medusa-page {
  display: flex;
  flex-direction: column;
}

.ant-breadcrumb {
  padding-bottom: 8px;
}

/**
hack for applications subitem popup when sidebar is collapsed
*/

.ant-menu-submenu-popup ul.ant-menu[id*='/applications-popup'] {
  padding: 16px;
}

/* stuff that supports the sidebar */

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  /* This overrides an Ant rule with "width: calc(100% + 1px)" which makes menu scroll horizontally */
  width: 100% !important;
  margin: 4px 0 8px 0;
}

.ant-menu.ant-menu-sub.ant-menu-vertical > .ant-menu-item {
  height: auto;
  margin: 4px 0 8px 0;
  display: flex;
}

.ant-layout-sider-children > ul {
  border-right: 0 !important;
}

.web-sider > div {
  display: flex;
  flex-direction: column;
}

.web-side-menu .ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.web-side-menu .ant-menu-inline-collapsed .ant-menu-submenu-arrow::after {
  margin-left: 2px;
}

.web-side-menu svg {
  overflow: visible;
}

/* place where we can bubble up styles shared around multiple pages */

.page-right-hand-actions {
  display: flex;
}

.page-right-hand-actions button:not(:last-child) {
  margin-right: 6px;
}

.card-items-wrap-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
}

.card-items-wrap-list-group-header {
  margin-bottom: 8px !important;
  font-size: 24px !important;
  margin-top: 32px !important;
}

.card-items-card h2 {
  font-size: 24px !important;
}

.card-items-card h3 {
  font-size: 18px !important;
}

@value sm-down, md-down from "./src/_shared/breakpoints.module.css";

@media (sm-down) {
  .card-items-wrap-list {
    display: block;
  }

  .card-items-wrap-list > :not(:first-child) {
    margin-top: 20px;
  }
}

@media (md-down) {
  .page-right-hand-actions {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
  }

  .page-right-hand-actions button:not(:last-child) {
    margin-right: 0;
  }

  .page-right-hand-actions > button {
    margin-bottom: 8px;
  }
}
